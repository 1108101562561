import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FooterColumn from './FooterColumn'
import lnicon from '../../assets/images/ln.svg'
import premierlogo from '../../assets/images/premier-logo.svg'
import twicon from '../../assets/images/tw.svg'
import * as styles from './Footer.module.scss'
import { useLocation } from '@reach/router'

export default function Footer(props) {

    const data = useStaticQuery(graphql`
    query {
        allSanityHomepage {
            nodes {
                _key
                footer: _rawFooterSection
            }
        }
    }`)

    const { footer } = data.allSanityHomepage.nodes[0]

    const genColumns = footer.footerLinkColumns.map((column, index) => {
        return (
            <FooterColumn data={column} key={index} styles={styles} columnNumber={index + 1}/>
        )
    })

      // detecting 100 Top home page for styles specific to that page
    const location = useLocation()
    const isHundredTop = location.pathname && location.pathname.includes('/100-top-hospitals')

    const classes = [styles.sectionFooter, isHundredTop ? styles.hundredTop : ''].filter(Boolean).join(' ')

    return (

        <div className={classes}>
            <div className={styles.sectionFooterContent} id="footer-content">
                <div className={styles.sectionFooterContentLeft}>
                    {genColumns}
                </div>
                <div className={styles.sectionFooterContentRight}>
                    <div className={styles.sectionFooterCol}>
                        <div className={styles.sectionFooterSocials}>
                            <a href="https://twitter.com/PINC_AI" target="_blank" rel="noopener noreferrer">
                                <img src={twicon} alt="twitter" />
                            </a>

                            <a href="https://www.linkedin.com/company/pinc-ai" target="_blank" rel="noopener noreferrer">
                                <img src={lnicon} alt="linkedin" />
                            </a>
                        </div>
                        <div className={styles.sectionFooterAddress}>
                            13034 Ballantyne Corporate Place
                            <br />
                            Charlotte, NC 28277
                        </div>
                        <div className={styles.sectionFooterPhone}>
                            <a href="tel:+1-704-357-0022"><strong>Phone:</strong> 704.357.0022</a>
                            <br />
                            <a href="tel:+1-877-777-1552"><strong>Solution Center:</strong> 877.777.1552</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.sectionFooterCopyright}>
                <a href="https://premierinc.com/" target="_blank" rel="noopener noreferrer">
                    <img src={premierlogo} alt="Premier Logo" className={styles.sectionFooterPremierLogo} />
                </a>
                <div className={styles.sectionFooterCopyrightText}>
                    {footer.footerCopyright.copyrightText || <>© 2022  Premier, Inc. - All Rights Reserved.</>}
                </div>
            </div>

            <div className={styles.mobileFooter}>
                <a href="https://premierinc.com/" target="_blank" rel="noopener noreferrer">
                    <img src={premierlogo} alt="Premier Logo" className={styles.sectionFooterPremierLogo} />
                </a>
                <div className={styles.footerAddress}>
                    13034 Ballantyne Corporate Place
                    <br />
                    Charlotte, NC 28277
                </div>

                <div className={styles.footerContact}>
                    <a href="tel:+1-704-357-0022"><strong>Phone:</strong> 704.357.0022</a>
                    <br />
                    <a href="tel:+1-877-777-1552"><strong>Solution Center:</strong> 877.777.1552</a>
                </div>

                <div className={styles.footerMobileLinks}>
                    <p></p>
                    <a href="https://www.premierinc.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                    <br />
                    <a href="https://www.premierinc.com/privacy-notice" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    <br />
                    {/* <!-- OptanonCookieSettingsButtonStart --> */}
                    <button id="ot-sdk-btn" className="ot-sdk-show-settings">CookieSettings</button>
                    {/* <!-- OptanonCookieSettingsButtonEnd --> */}
                </div>

            </div>

            <div className={styles.footerMobileCopyright}>
                {footer.footerCopyright.copyrightText || <>© 2022  Premier, Inc. - All Rights Reserved.</>}
            </div>
        </div>
    )
}
