import React from 'react';
import '../../styles/index.scss'
import Footer from '../Footer/Footer';
import Nav from '../Nav/Nav';



export default function Layout({ background = 'dark', children }) {

  return (
    <main className="site-body">
      <Nav background={background}></Nav>
      {children}
      <Footer />
    </main>
  )
}
