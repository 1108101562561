import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout/Layout'
import Spinner from '../components/Spinner/Spinner'
import { Link } from 'gatsby'
import * as styles from '../styles/placeholderPages.module.scss'

/* placeholder page for 50 top cardiovascular hospitals results */

const Top50Results = (props) => {
  // const {} = props
  const [isloading, setIsloading] = useState(true)

  useEffect(() => {
    // Add the class to the body
    document.body.classList.add('white-bg')
    setIsloading(false)

    // Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('white-bg')
    }
  }, [])

  if (isloading) {
    return (
      <Layout>
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.sectionWrapper}>
          <section className={styles.section}>
            <h1 className={styles.h1}>50 Top Cardiovascular Hospitals™ Results</h1>
            <h2 className={styles.h2}>Launching Soon.</h2>
            <Link to="/100-top-hospitals/50-top-cardiovascular-hospitals" className={styles.button}>
              <span>Back</span>
            </Link>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Top50Results
