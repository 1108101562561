import React from 'react'
// import { Link } from 'gatsby'

export default function FooterColumn(props) {

    const { data, styles, columnNumber } = props

    const titleWords = data.title.split(' ')

    if (titleWords.length > 1) {
        titleWords[0] = <span key="0">{titleWords[0]}</span>
    }

    const genLinks = data.links.map((link, index) => {
        // const isBrowser = typeof window !== "undefined"
        var href = link.link

        // if (isBrowser) {
        //      href = link.link || window.location.href
        // }
        // else{
        //      href = link.link || window.location.href
        // }

        return (
            <li key={index}>
                <a href={href}>{link.text}</a>
            </li>
        )
    })

    return (
        <div className={styles.sectionFooterCol}>
            <div className={styles.sectionFooterColTitle}>{titleWords}</div>
            <ul>
                {genLinks}
                {/* add cookie settings link */}
                {columnNumber === 3 && (
                    <li>
                        {/* <!-- OptanonCookieSettingsButtonStart --> */}
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">CookieSettings</button>
                        {/* <!-- OptanonCookieSettingsButtonEnd --> */}
                    </li>
                )}

            </ul>
        </div>
    )
}
